import { defineComponent, nextTick } from 'vue'
import SiteStore, { currentStore } from './models/store'
import User from './models/user'

export default defineComponent({
    data() {
        var emptyUser = new User();
        return {
	    user: emptyUser,
	    emptyUser,
	    authToken: null,
            
            ready: false,
            working: false,
            loading: false,
            store: currentStore
        };
    },
    methods: {
        showError(msg) {
            console.log("showError", msg);
        },
        showSuccess(msg) {
            console.log("showSuccess", msg);
        },        
	changeUser(newUser) {
	    if (!newUser.id && this.user.username) {
		this.signOutToken();
		this.authToken = null;
	    }

	    this.user = newUser;
	},
	logout() {
	    this.changeUser(this.emptyUser);
	    this.$router.push("/");
	},
	setLoginToken(email, data) {
	    const retSet = localStorage.setItem(email, JSON.stringify(data));
	    const currentUsers = this.listLoginToken();
	    if (!currentUsers.includes(email)) {
		currentUsers.push(email);
		localStorage.setItem("users", JSON.stringify(currentUsers));
	    }

	    this.useLoginToken(email);
	    return retSet;
	},
	listLoginToken() {
	    return JSON.parse(localStorage.getItem("users")) || [];
	},
	getActiveUser() {
	    return localStorage.getItem("active-user");
	},
	removeLoginToken(email) {
	    const currentUsers = this.listLoginToken().filter(x => x != email);
	    localStorage.setItem("users", JSON.stringify(currentUsers));
	    if (this.getActiveUser() == email) {
		localStorage.removeItem("active-user");
	    }
	    return localStorage.removeItem(email);
	},
	getLoginTokenData(email) {
	    return JSON.parse(localStorage.getItem(email));
	},
	useLoginToken(email) {
	    const getToken = this.getLoginTokenData(email);
	    if (!getToken) return;
	    localStorage.setItem("active-user", email);
	    this.authToken = getToken;
	    this.axios.defaults.headers.common["Authorization"] = "Bearer " + this.authToken.token;
	},
	signOutToken() {
	    let tokenUser = this.user.username ? this.user.username : this.listLoginToken()[0];
	    this.removeLoginToken(tokenUser);
	    delete this.axios.defaults.headers.common["Authorization"];
	    //delete this.axios.defaults.headers.common["X-SSO-Token"];
	},
	getMe() {
	    return new Promise((resolve, reject) => {
		this.apiGet("/me")
		    .then(response => {
			this.changeUser(response.data);
			resolve(response.data);
		    })
		    .catch(() => {
			if (this.authToken) {
			    this.signOutToken();
			}

			reject();
		    });
	    });
	},
	apiGet(path, data) {
	    if (typeof data == "undefined") data = null;
	    return this.axios
		.get(path, {
		    params: data,
		});
	},
	apiPost(path, data) {
	    return this.axios.post(path, data);
	}        
    }
})
