import { define, auto, Collection, Record } from 'type-r'

@define
export class PleskAliasCollection<R extends PleskAlias = PleskAlias> extends Collection<R> {
}

@define
export default class PleskAlias extends Record {
    static Collection = PleskAliasCollection<PleskAlias>;
    @auto('') name: string
    @auto('') ascii_name: string
    @auto(false) web: boolean
    @auto(false) dns: boolean
    @auto(false) mail: boolean
    @auto(false) seo_redirect: boolean
}
