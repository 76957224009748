import { Record, auto, type, define, Collection } from 'type-r'

import CommonModel, { CommonModelCollection } from '../common'
import PleskAlias, { PleskAliasCollection } from './alias'

@define
export class PleskDomainCollection<R extends PleskDomain = PleskDomain> extends CommonModelCollection<R> {
}

@define
export default class PleskDomain extends CommonModel {
    static Collection = PleskDomainCollection<PleskDomain>
    
    @auto('') created: string
    @auto('') name: string
    @auto('') ascii_name: string
    @auto(0) base_domain_id: number
    @auto('') guid: string
    @auto('') hosting_type: string
    @auto('') www_root: string
    @type(PleskAliasCollection).as aliases: PleskAliasCollection
}
