import {define, auto, type} from  'type-r'
import Collection, { Record } from './base'

@define
export class CommonModelCollection<R extends CommonModel = CommonModel> extends Collection<R> {}

@define
export default class CommonModel extends Record {
    static Collection = CommonModelCollection<CommonModel>
    @type(Date).as createdAt: Date
    @type(Date).as updatedAt: Date
    @type(Date).value(null).as deletedAt: Date
}
