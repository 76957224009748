import { defineComponent, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import BaseComponent from '@ui/base/component'

import User from '@models/user'
import * as Yup from 'yup';

export default defineComponent({
    extends: BaseComponent,
    data() {
        var cuser = new User({username: '', password: ''});
        const schema = Yup.object().shape({
            username: Yup.string().required(),
            password: Yup.string().min(6).required()
        });
        
        return {
            schema,
            cuser
        }
    },
    methods: {
        onSubmit(values) {
            console.log("VALUES",values);
            this.post(values);
        },
        onInvalidSubmit(result) {
        },
        validateUsername(value) {
            if (!value || value.length < 2)
                return 'Invalid username';
            return true;
        },
        validatePassword(value) {
            if (!value || value.length < 2)
                return 'Invalid password';
            return true;
        }
    }
});
