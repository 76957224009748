import { define, auto, type, subsetOf, memberOf } from 'type-r'
import { restfulIO } from '@endpoints/restfulIO'

import Collection, { Record } from './base'
import CommonModel, { CommonModelCollection } from './common'

import { PluginCollection } from './plugin'
import PleskDomain from './plesk/domain'
import { LogFileCollection } from './logfile'

@define
export class WebsiteCollection<R extends Website = Website> extends CommonModelCollection<R> {
}

@define
export default class Website extends CommonModel {
    static endpoint = restfulIO('/websites')
    static Collection = WebsiteCollection<Website>;
    @type(PleskDomain).as plesk: PleskDomain

    @type(LogFileCollection).as logfiles: LogFileCollection
    
    @auto('') siteurl: string
    @auto('') sitetype: string
    @auto(false) enabled: boolean
    @auto(false) logging: boolean
    
    static create(attrs, opts) {
        if (attrs && attrs.sitetype == 'wordpress') {
            let Wordpress = require('./wordpress').default;
            return new Wordpress(attrs, opts);
        }
        else {
            return new Website(attrs, opts);
        }
    }
}
