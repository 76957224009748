import { define, type } from 'type-r'
import { restfulIO } from '@endpoints/restfulIO'
import Collection, { Record } from './base'

@define
export class LogFileCollection<R extends LogFile = LogFile> extends Collection<R> {
}

@define
export default class LogFile extends Record {
    static endpoint = restfulIO('/logfiles')
    static Collection = LogFileCollection<LogFile>;

    @type(String).as filename: string
    @type(String).as type: string
    @type(Boolean).toJSON(false as any).as watching: boolean
    
}
