import _ from 'underscore'
import async from 'async'
import { type, define, auto, Record } from 'type-r'
import { defineComponent, nextTick } from 'vue'
import { currentStore } from '@models/store'
import TableComponent from '@ui/base/table';
import FilterModel from './filter'
import * as Highcharts from 'highcharts';

export default defineComponent({
    extends: TableComponent,
    props: {
    },
    data() {
        var filter = new FilterModel()
        filter.on("change", this.getDataFromApi, this)
        nextTick(() => this.initFn());
        
        return {
            statuses: ['active', 'inactive', 'all'],
            tableurl: window.location.href,
            filter,
            itemsPerPage: -1,
            expanded: [],
            headers: [
                {title:"ID", key:"id"},
                {title:"Name", key:"name"},
                {title:"Status", key:"status"},
                {title:"Avg Load", key:"avgload"},
                {title:"Logging", key:"logging"},
                {title:"Enabled", key:"enabled"},
                {title: "", key: "btns"}
            ]
        };
    },
    computed: {
    },
    methods: {
        initFn() {
            this.getDataFromApi();            
        },
        transformData(data) {
            _.each(data.items, (item, idx) => {
                data.items[idx].loadingLogging = null;
                data.items[idx].loadingEnabled = null;                
            });
            return data;
        },
        
        toggleLogging(item) {
            item.columns.loadingLogging = "warning";
            this.cmd(`/pages/${item.columns.id}/logging/${item.columns.logging}`, {}, (data) => {
                item.columns.loadingLogging = null;
            });
        },
        
        toggleEnabled(item) {
            item.columns.loadingEnabled = "warning";
            this.cmd(`/pages/${item.columns.id}/enabled/${item.columns.enabled}`, {item}, (data) => {
                item.columns.loadingEnabled = null;
            });            
        },
        loadChart(item) {
            var renderTo = `highchart-${item.raw.id}`;
            this.cmd(`/pages/${item.raw.id}/historical`, {}, (loadData) => {
                loadData.x = _.map(loadData.x, x => x * 1000);                
                loadData = _.zip(loadData.x, loadData.y);
                console.log("loadData",loadData);
            async.whilst(
                cb => cb(null, document.getElementById(renderTo) == null),
                cb => setTimeout(() => cb(), 100),
                err => {
                    var c = new Highcharts.Chart({
                        chart: {
                            renderTo,                    
                            scrollablePlotArea: {
                                minWidth: 700
                            }
                        },
                        title: {
                            text: 'Historical Performance',
                            align: 'left'
                        },
                        subtitle: {
                            text: item.raw.url,
                            align: 'left'
                        },
                        xAxis: {
                            type: 'datetime',
                            labels: {
                                align: 'left'
                            }
                        },
                        yAxis: [{ // right y axis
                            linkedTo: 0,
                            gridLineWidth: 0,
                            opposite: true,
                            title: {
                                text: null
                            },
                            showFirstLabel: false
                        }],

                        legend: {
                            align: 'left',
                            verticalAlign: 'top',
                            borderWidth: 0
                        },

                        tooltip: {
                            shared: true
                        },

                        plotOptions: {
                            series: {
                                cursor: 'pointer',
                                className: 'popup-on-click',
                                marker: {
                                    lineWidth: 1
                                }
                            }
                        },

                        series: [{
                            name: 'Load Time',
                            type: "line",
                            data: loadData,
                            lineWidth: 4,
                            marker: {
                                radius: 4
                            }
                        }]
                    });
                });
            });
        }
    }
});
