import { define, type, auto } from 'type-r'
import { Email } from 'type-r/ext-types'

import { restfulIO } from '@endpoints/restfulIO'

import CommonModel from './common'
import Collection, { Record } from './base'
import WebsiteCollection from './website'

@define
export class CompanyCollection<R extends Company = Company> extends Collection<R> {}

@define
export class ProfileFields extends Record {
    @auto contact_person: string
    @auto address: string
    @auto phone: string
    @auto fax: string
    @type(Email).as email: string
}

@define
export default class Company extends CommonModel {    
    static endpoint = restfulIO('/companies')
    static Collection = CompanyCollection<Company>;
	
    @auto name: string
    @type(Boolean).as isActive: boolean
    @type(ProfileFields).as profileFields: ProfileFields
    @type(WebsiteCollection).as websites : WebsiteCollection
}
