import { define, type, auto, Collection, Record, Store } from 'type-r'
import { WebsiteCollection } from './website'
import { PluginCollection } from './plugin'
import { PleskDomainCollection } from './plesk/domain'
import { CompanyCollection } from './company'
import { ContactCollection } from './contact'
import { Config } from '../config'

import User from './user'
import Company from './company'

@define
export default class SiteStore extends Store {
    @type(WebsiteCollection).as sites: WebsiteCollection
    @type(CompanyCollection).as companies: CompanyCollection
    @type(ContactCollection).as contacts: ContactCollection
    @type(User).as current_user: User
    @type(Company).as current_company: Company
    @type(Config).as config: Config
}

var store = new SiteStore();
export { store as currentStore };
