import _ from 'underscore'
import { type, define, auto, Record } from 'type-r'
import { defineComponent, nextTick } from 'vue'
import { currentStore } from '@models/store'
import TableComponent from '@ui/base/table';
import FilterModel from './filter'

export default defineComponent({
    extends: TableComponent,
    props: {
    },
    data() {
        var filter = new FilterModel()
        filter.on("change", this.getDataFromApi, this)
        nextTick(() => this.initFn());
        
        return {
            statuses: ['active', 'inactive', 'all'],
            tableurl: window.location.href,
            filter,
            itemsPerPage: -1,            
            expanded: [],
            headers: [
                {title:"ID", key:"id"},
                {title:"Name", key:"name"},
                {title:"Enabled", key:"enabled"},
                {title: "", key: "btns"}
            ]
        };
    },
    computed: {
    },
    methods: {
        initFn() {
            this.getDataFromApi();            
        },
        transformData(data) {
            _.each(data.items, (item, idx) => {
                data.items[idx].loadingLogging = null;
                data.items[idx].loadingEnabled = null;                
            });
            return data;
        },
        toggleLogging(item) {
            item.columns.loadingLogging = "warning";
            this.cmd(`/websites/${item.columns.id}/logging/${item.columns.logging}`, {}, (data) => {
                item.columns.loadingLogging = null;
            });
        },
        toggleEnabled(item) {
            item.columns.loadingEnabled = "warning";
            this.cmd(`/websites/${item.id}/enabled/${item.enabled}`, {item}, (data) => {
                item.columns.loadingEnabled = null;
            });            
        }
    }
});
