import "reflect-metadata";
import { define, Collection, Record as Rec, type, auto, shared, memberOf, subsetOf } from 'type-r'

@define
export default class Col<R extends Record = Record> extends Collection<R> {
    sameDay(date1:Date, date2:Date) {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
    }
}

@define
export class Record extends Rec {
    static Collection = Col<Record>;
    errors: {[key:string]: string} = {}

    static check() {
        var ob = {
            validate: function(value, {model, name}) {
                var valid = model.isValid(name);
                if (!valid) {
                    return model.getValidationError(name);
                }
                console.log(name,"is valid");
                return true;
            },
            params: ['model', 'name']
        };
        return ob;
    }

    getStore() {
        return window.app.store;
    }
}
