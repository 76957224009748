// import './assets/main.css'
import { createApp } from 'vue'
import App from './Login.vue'

import { createRouter, createWebHistory } from 'vue-router'
import * as v from './views/index'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
      { path: '/login', name: 'login', component: v.LoginView },
      { path: '/about', name: 'about', component: v.LoginView },
      { path: '/privacy-policy', name: 'privacy-policy', component: v.LoginView }
  ]
});

import axios from "axios";

import fancypass from './ui/fancypass/fancypass.vue'
const app = createApp(App)

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { Form, Field, ErrorMessage } from 'vee-validate'

const vuetify = createVuetify({
    components: {
        fancypass,
        Form,
        Field,
        ErrorMessage,
        ...components
    },
  directives,
});

app.use(router)
app.use(vuetify)
app.mount('#app')
