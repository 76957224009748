import { type, auto, define } from 'type-r'
import { Email, Url } from 'type-r/ext-types'
import _ from 'underscore'

import { Password } from '@typs/password'

import { Record } from './base'
import CommonModel, { CommonModelCollection } from './common'
import { CompanyCollection } from './company'

@define class ProfileFields extends Record {
    @type(String).required.as first_name: string
    @type(String).required.as last_name: string
    @auto employee_number: string
    @auto('') title : string
    @auto('') phone : string
}

@define
export class ContactCollection<R extends Contact = Contact> extends CommonModelCollection<R> { }


@define
export default class Contact extends CommonModel {
    static Collection = ContactCollection<Contact>;

    @auto contactname: string
    @auto groupLevel: number
    @type(Email).as email: string
    @auto lastLogin: Date
    @auto loginHash: string
    @type(Password).as password: string
    @type(Password).as password1: string
    @auto companyId: number
    @type(ProfileFields).as profileFields: ProfileFields
    @type(CompanyCollection).as companies: CompanyCollection
    @auto authenticators: any[]
    @auto isAuthenticated: boolean
    @auto session: any
    @auto flow: any
    @auto devmode: number
    @auto lockout: boolean
    @auto passwordreset: boolean
    @auto loginAttempts: number

    getFullName() {
        return this.profileFields.first_name + " " + this.profileFields.last_name;
    }

    profileField(key) {
        return this.profileFields[key];
    }

    // SSO permission is whatever your highest groupLevel associated with service is
    hasAccess(lvl, companyId?:number|string) {
        if (!companyId) {
            var company = this.getStore().current_company;
            companyId = company.id;
        }

        var cu = null;
        return window.app.hasAccess(0, lvl);
    }

    isEr() {
        return this.hasAccess('is.era') || this.hasAccess('is.erz') || this.hasAccess('is.ern');
    }

    currentGroupLevel(companyId = null) {
        if (!companyId) {
            var company = this.getStore().current_company;
            companyId = company.id;
        }

        return 0;
    }

    hasMultipleCompanies() {
        if (this.companies && this.companies.length > 1)
            return true;
    }

    hasActiveCompany(companyId) {
        var contactcomp = this.companies.find(cu => cu.id == companyId);
        if (contactcomp) return true;
    }

    groupsDisabled(ccontact) {
        var groups = this.getStore().groups;
        var contact = this;

        if (!this.hasAccess('atleast.wsa')) {
            if (ccontact.currentGroupLevel() <= groups.getGroupLevelByRole('BAS') || ccontact.currentGroupLevel() >= groups.getGroupLevelByRole('ERZ'))
                return true;
        }
        return false;
    }

    myGroupList () {
        var gr = this.getStore().groups.toJSON();
        let groupLevel = this.currentGroupLevel();
        gr = _.filter(gr, group => group.groupLevel <= groupLevel)
        return gr;
    }

    groups (mode, ccontact) {
        let gr = this.myGroupList();
        var groups = this.getStore().groups;
        if (!this.hasAccess('atleast.wsa')) {
            // Disable group level selection if a non-WSA contact is editing an existing BAS/COM contact (per client request)
            if (this.groupsDisabled(ccontact)) {
            }
            else {
                if (mode == 'create') {
                    gr = _.filter(gr, g => g.name != 'COM' && g.name != 'BAS');
                }
            }
            if (this.hasAccess('is.era')) {
                if (mode == 'create') {
                    gr = _.filter(gr, g => g.name != 'ERA');
                }
            }
            if (this.hasAccess('is.erz')) {
                if (mode == 'create') {
                    gr = _.filter(gr, g => g.name != 'ERZ');
                }
            }
        }
        return gr;
    }
}
