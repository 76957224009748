import { define, auto, type, subsetOf } from 'type-r'
import _ from 'underscore'

import Collection, { Record } from './base'
import Website, {WebsiteCollection} from './website'
import Plugin, { PluginCollection } from './plugin'
import config from '../config'

@define
export class WordpressCollection<R extends Wordpress = Wordpress> extends WebsiteCollection<R> {
}

@define
export default class Wordpress extends Website {
    static Collection = WordpressCollection<Wordpress>;

    @type(PluginCollection).as plugins : PluginCollection

}
