import { define, type, auto, Model } from 'type-r'
import _ from 'underscore'

import { restfulIO } from '@endpoints/restfulIO'

import Collection from './models/base'
import CommonModel, { CommonModelCollection } from './models/common'

@define class ConfigCollection<R extends Config = Config> extends CommonModelCollection<R>{}

@define
export class Config extends CommonModel {
    static endpoint = restfulIO('/config')
    static Collection = ConfigCollection

    @type(String).as siteurl : string
    @type(Number).value(3).as maxLoginAttempts: number

    ready : boolean = false
    @auto('') rootdir : string
    @auto('') vhosts : string
    @auto('') wpcmd : string
    @auto('') mysqldump: string
    @auto('') mysql: string
    @auto('') WORLD_PATH: string

    @auto({}) plesk : any
    @auto({}) git : any
    
    parse(json) {
        return json;
    }
}
var mysqldir = "C:\\Progra~1\\MariaDB 10.6\\bin\\";
var config = new Config({
    'wpcmd': `C:\\Progra~2\\Plesk\\Additional\\PleskPHP74\\php.exe C:\\Windows\\wp-cli.phar`,
    'vhosts': "C:\\inetpub\\vhosts",
    'mysqldump': `${mysqldir}mysqldump.exe`,
    'mysql': `${mysqldir}mysql.exe`,
    'git': {
        cmd: "C:\\Program Files\\Git\\bin\\git.exe"
    },
    'plesk': {
        url: {
            host: 'mcneesupport.com',
        },
        credentials: {
            login: 'Administrator',
            password: 'Hei4k8NS9L!Hv8ks',
        }
    }

}, {parse:true});


export default config
