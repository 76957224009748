import { define, type, auto, shared, value } from 'type-r'
import { Email } from 'type-r/ext-types'
import _ from 'underscore'

import { Password } from '@typs/password'
import SimpleJson from '@typs/simple_json'
import PowerBool from '@typs/power_boolean'

import { restfulIO } from '@endpoints/restfulIO'

import Collection from './base'
import CommonModel, { CommonModelCollection } from './common'

const actionMap = {
    "registration": 0,
    "login": 1,
    "change": 2,
};

@define
export class UserCollection<R extends User = User> extends CommonModelCollection<R>{ }

@define
export default class User extends CommonModel {
    static endpoint = restfulIO('/users')
    static Collection = UserCollection<User>

    @type(String).check(v => v && v.length > 3, 'Staffname not valid').as username: string
    @type(Password).as password: string
    @type(Email).as email: string
    @type(Boolean).as lockout : boolean
    @type(Number).as loginAttempts : number
    @value(null).check(v => v instanceof Date || !v, 'Invalid last login value').as lastLogin: Date
    @auto loginHash: string
    @type(SimpleJson).as profileFields: any

    initialize(atts, opts) {
        super.initialize(atts, opts);
        this.on("change:profileFields", this.updateProfileFields, this);
    }

    updateProfileFields() {
        if (this.profileFields && typeof this.profileFields == 'string') {
            this.profileFields = JSON.parse(this.profileFields);
        }
    }

    loggedIn() {
        return this.id != null;
    }
}
