export { default as LoginView } from './Login.vue'
export { default as DashboardView } from './Dashboard.vue'
export { default as SettingsView } from './Settings.vue'
export { default as LogsView } from './Logs.vue'
export { default as SitemapView } from './Sitemap.vue'

export { default as CompaniesView } from './companies/List.vue'
export { default as CompanyView }  from './companies/View.vue'
export { default as CompanyEditView } from './companies/Edit.vue'

export { default as ContactsView } from './contacts/List.vue'
export { default as ContactView } from './contacts/View.vue'
export { default as ContactEditView } from './contacts/Edit.vue'

export { default as StaffsView } from './staffs/List.vue'
export { default as StaffView } from './staffs/View.vue'
export { default as StaffEditView } from './staffs/Edit.vue'

export { default as WebsitesView } from './websites/List.vue'
export { default as WebsiteView }  from './websites/View.vue'
export { default as WebsiteEditView } from './websites/Edit.vue'

export { default as PluginsView } from './plugins/List.vue'
export { default as PluginView }  from './plugins/View.vue'
export { default as PluginEditView } from './plugins/Edit.vue'

export { default as PagesView } from './pages/List.vue'
export { default as PageView }  from './pages/View.vue'
export { default as PageEditView } from './pages/Edit.vue'
