import { defineComponent } from 'vue'
import _  from "underscore";
import $ from 'jquery'
import { Record, Collection } from 'type-r'

import { currentStore } from '@models/store'

export default defineComponent({
    data: function() {
        return {
            title: '',
            subtitle: '',
            body_class: '',
            template_blank: null
        };
    },
    computed: {
        company: function() {
            return currentStore.current_company;
        },
        user: function() {
            return currentStore.current_user;
        },
        config: function() {
            return currentStore.config;
        },
        store: function() {
            return currentStore;
        }
    },
    watch: {
        $route: function(to, from) {
            this.initFn();
        }
    },
    methods: {
        fetch: function(url?, data?) {
            url = url || window.location.href;
            data = data || {};
            data.vue = 1;
            $.ajax({
                url:url,
                data,
                cache: false,
                success: (data) => {
                    data = this.procmsg(data);
                    this.fetched(data);
                },
                error: (code, data) => {
                    this.errored(code, data);
                },
                type: 'get'
            });
        },
        procmsg: function(data) {
            if (data.success && data.success.length) {
                for (var x = 0; x < data.success.length; x++) {
                    this.$emit('success', data.success[x]);
                }
            }
            if (typeof data.error == 'object') {
                for (var k in data.error) {
                    k = k.toString();
                    this.$emit('error', data.error[k]);
                }
            }
            else if (data.error) {
                console.error("procmsg err: ",data);
                this.$emit('error', data.error);
            }

            if (data.info) {
                var info = data.info;
                if (info.url)
                    this.goTo(info.url);
                else {
                    currentStore.set(info, {parse:true});
                }
            }
            return data.data;
        },
        goTo: function(path) {
            var url = new URL(path, window.location.href);
            window.location.href = url.href;
        },
        procerr: function(data) {
            if (typeof data == 'string') {
                console.error("procerr err: ",data);
                this.$emit('error', data);
            }
            else {
                console.error("procerr err: ",data);
                if (data.error && data.error.length) {
                    for (var x = 0; x < data.error.length; x++) {
                        this.$emit('error', data.error[x]);
                    }
                }
            }
        },
        fetched: function(data) {
            var appkeys = ["current_user", "current_company"];
            _.each(appkeys, appkey => {
                if (data && data.hasOwnProperty(appkey)) {
                    if (currentStore[appkey] instanceof Record) {
                        if (currentStore[appkey].id == data[appkey].id)
                            currentStore[appkey].set(data[appkey], {parse: true});
                        else {
                            currentStore[appkey] = new (currentStore[appkey].__proto__.constructor)(data[appkey], {parse:true});
                        }
                    }
                    else if (currentStore[appkey] instanceof Collection) {
                        if (typeof data[appkey] == 'object')
                            currentStore[appkey].set(Object.values(data[appkey]), {parse: true});
                        else
                            currentStore[appkey].set(data[appkey], {parse: true});
                    }
                    else {
                        currentStore[appkey] = data[appkey];
                    }
                }
            });

            for (var k in data) {
                if (this.hasOwnProperty(k)) {
                    if (this[k] instanceof Record)
                        this[k].set(data[k], {parse: true});
                    else if (this[k] instanceof Collection) {
                        if (typeof data[k] == 'object')
                            this[k].set(Object.values(data[k]), {parse: true});
                        else
                            this[k].set(data[k], {parse: true});
                    }
                    else {
                        this[k] = data[k];
                    }
                }
                else
                    console.error("Didn't assign unenumerated property ", k);
            }
        },
        post: function(data?, url?, cb?, errcb?) {
            url = url || window.location.href;
            data = data || {};
            let ajaxopts:JQuery.AjaxSettings = {
                url:url,
                data:data,
                cache: false,
                success: (data) => this.posted(this.procmsg(data)),
                error: (code, data) => this.errored(code, data),
                type: 'post',
                method: 'post'
            };
            if (data instanceof FormData) {
                ajaxopts.processData = false;
                ajaxopts.contentType = false;
                (ajaxopts.data as FormData).append('vue', '1');
            }
            else {
                (ajaxopts.data as any).vue = 1;
            }
            if (cb) {
                ajaxopts.success = (data) => cb(this.procmsg(data));
            }
            if (errcb) {
                ajaxopts.error = (code, txt) => errcb(this.procmsg(data));
            }
            $.ajax(ajaxopts);
        },
        posted: function(data) {

        },
        cmd: function(url?, data?, cb?) {
            if (typeof data == 'function') {
                cb = data;
                data = null;
            }
            url = url || window.location.href;

            data = data || {};
            data.vue = 1;
            $.ajax({url:url, cache: false, data, success: (data) => cb ? cb(this.procmsg(data)) : this.cmdfinish(this.procmsg(data)), error: (code, data) => this.errored(code, data), type: 'get'});
        },
        cmdfinish: function(data) {

        },
        errored: function(code, txt) {
            this.$emit('error', txt);
        },
        initFn: function() {
        },
        afterUpdate: function(cb) {
            this.$nextTick(() => cb());
        }
    }
})
