import { define, auto, type } from 'type-r'

import Collection, { Record } from './base'

import config from '../config'

@define
export class PluginCollection<R extends Plugin = Plugin> extends Collection<R> {
}

@define
export default class Plugin extends Record {
    static Collection = PluginCollection<Plugin>;
    @auto('') dir: string
    @auto('') name: string

}
