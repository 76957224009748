import "reflect-metadata";
import { define, IOEndpoint, createIOPromise, Record, Collection } from 'type-r'
import { RestfulEndpoint } from 'type-r/endpoints/restful'
import _ from 'underscore'

export function create<R extends Record = Record>(schema, opts?) {
    return new RestfulIOEndpoint<R>(schema, opts);
}

export interface QueryOpts {
    limit?: [number, number] | string
    where?: string
    query?: string
    jointable?: string
    joincolumn?: string
    params?: any[]
}

export { create as restfulIO }

@define
export default class RestfulIOEndpoint<R extends Record = Record> extends RestfulEndpoint implements IOEndpoint {

}
