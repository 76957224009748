import { AttributeCheck, type } from 'type-r';

export function isPassword( pwd : string ) {
    if (pwd.length < 8) {
        fn.error = 'Password must be a minimum of 8 characters in length.';
        return false;
    }
    var criteria = 0;
    if (pwd.match(/[0-9]/))
        criteria++;

    if (pwd.match(/[a-z]/))
        criteria++;

    if (pwd.match(/[A-Z]/))
        criteria++;

    if (pwd.match(/[^0-9a-zA-Z]/))
        criteria++;

    if (criteria < 3) {
        fn.error = '<div style="text-align:left">Password Requirements: <ul><li>Minimum 8 Characters</li><li>3 of 4 Items:<ul><li>Number</li><li>Lowercase letter</li><li>Uppercase letter</li><li>Special character: [!#$%^&*()+=-[],./{}|:<>?~]</li></ul></li></ul></div>';
        return false;
    }
    return true;
}
var fn = (isPassword as AttributeCheck);
fn.error = 'Password is not <b>valid</b>'

export const Password = type( String ).check( isPassword );
