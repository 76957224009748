import { defineComponent } from 'vue'
import { define } from 'type-r'
import _ from 'underscore'

import { Record } from '@models/base'

import BaseComponent from './component'

@define export class FilterModel extends Record {

}
export default defineComponent({
    extends: BaseComponent,
    data: function() {
        var filter = new FilterModel()
        return {
            filter: filter,
            options: {},
            items: [],
            totalItems: 0,
            bulkAction: "",
            timerCounter: 0,
            loading: true,
            showbulk: false,
            tableurl: 'tableurl',
            bulkurl: 'bulkurl'
        }
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true
        }
    },
    methods: {
        initFn: function() {
            this.getDataFromApi();
        },
        selchange: function() {
            this.showbulk = Object.keys(this.$refs.table.modelValue).length > 0;
        },

        getDataFromApi: function(event?, instant = true) {
            this.showbulk = Object.keys(this.$refs.table.modelValue).length > 0;
            if (this.timerCounter) {
                clearTimeout(this.timerCounter);
                this.timerCounter = null;
            }

            this.timerCounter = setTimeout(() => {
                this.loading = true;
                this.apiCall().then((data:any) => {
                    if (this.transformData)
                        data = this.transformData(data);
                    this.totalItems = data.totalItems;
                    this.items = data.items;
                    this.loading = false;
                    // (this as any).$vuetify.goTo(this.$refs.table, 500)
                }).catch(err => {
                    console.error(err);
                })
            }, instant ? 5 : 1500) as any;
        },
        mapBulkObject(m) {
            return {id: m.id};
        },
        bulksubmit: function() {
            var selection = {items: _.map(Object.values(this.$refs.table.modelValue), (m) => this.mapBulkObject(m))};
            this.post(selection, this.bulkurl.replace(":action", this.bulkAction), (d) => {
                this.getDataFromApi();
            }, err => {
                console.error(err);
            });
        },
        apiCall: function() {
            return new Promise((resolve, reject) => {
                var opts = _.extend(this.options, this.filter.toJSON());
                this.post(opts, this.tableurl, (d) => {
                    resolve(d)
                }, err => {
                    reject(err)
                });
            })
        }
    }
})
